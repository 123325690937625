import GoogleLogo from "./images/google.png";
import AmazonLogo from "./images/amazon.png";
import NetflixLogo from "./images/netflix.png";
import MyLogo from "./images/azhar.png";
import TrackerLogo from "./images/tracker.png";
import CVALogo from "./images/cva.png";
import WordPress from "./wp.png";
import CodeGodown from "./images/cd.png";

export const assets = {
  GoogleLogo,
  AmazonLogo,
  NetflixLogo,
  MyLogo,
  TrackerLogo,
  CVALogo,
  WordPress,
  CodeGodown,
  AirbnbLogo: "https://links.papareact.com/qd3",
};
