export const assets = {
  Amazon: "https://i.ibb.co/nzNbr0d/Amazon.jpg",
  eBay: "https://i.ibb.co/1JYcWRX/eBay.jpg",
  BestBuy: "https://i.ibb.co/z85X5ht/BestBuy.jpg",
  Macys: "https://i.ibb.co/gm0LVqD/Macys.jpg",
  Traackr: "https://i.ibb.co/phFjyWv/Traackr.jpg",
  PlanNative: "https://i.ibb.co/MGjt9dK/Plan-Native.jpg",
  Azhar: "https://i.ibb.co/WsQD0Jj/Azhar.jpg",
  CompanyPortfolio: "https://i.ibb.co/jZPTTSN/Company-Portfolio.jpg",
  CVA: "https://i.ibb.co/0c0gpDX/VA.jpg",
  CovidTracker: "https://i.ibb.co/zQgbhTT/Covid-Tracker.jpg",
  beingGuru: "https://i.ibb.co/HCwrm1y/being-Guru.jpg",
  DicasOnline: "https://i.ibb.co/8bXFws6/Dicas-Online.jpg",
};
