import { assets } from "../IcanCreate/assets/assets";

export const ICanCreate = [
  {
    title: "Enterprise level JavaScript(ReactJs) Web Application",
    imgUrl1: assets.CVA,
    imgUrl2: assets.CovidTracker,
    feature1: "Clean, Testes Bugless code",
    feature2: "Interactive user interface",
    feature3: "Advance animations",
    feature4: "Unlimited APIs integrations",
    feature5: "Elegant design",
    feature6: "Data rich UI",
  },
  {
    title: "A multi-vendor eCommerce marketplace like Amazon | eBay",
    imgUrl1: assets.Amazon,
    imgUrl2: assets.eBay,
    feature1: "Complete vendors setup",
    feature2: "Commissions setup",
    feature3: "Complete shipping setup",
    feature4: "Complete payments setup",
    feature5: "Unlimited sellers",
    feature6: "Unlimited products",
  },
  {
    title: "A fully-featured eCommerce store like Macys",
    imgUrl1: assets.Macys,
    imgUrl2: assets.BestBuy,
    feature1: "Complete shipping setup",
    feature2: "Complete payments setup",
    feature3: "Complete products setup",
    feature4: "Fully-functional store",
    feature5: "Unlimited products",
    feature6: "Next-level descent design",
  },
  {
    title: "Enterprise level business website for your business",
    imgUrl1: assets.Traackr,
    imgUrl2: assets.PlanNative,
    feature1: "Elegant design",
    feature2: "Interactive user interface",
    feature3: "Advance animations",
    feature4: "Leads generating ui",
    feature5: "SEO optimized",
    feature6: "All needed pages",
  },
  {
    title: "A Blog site for Blogging or Affiliate marketing",
    imgUrl1: assets.beingGuru,
    imgUrl2: assets.DicasOnline,
    feature1: "SEO Optimized",
    feature2: "Eye-catching design",
    feature3: "Full blog functionalities",
    feature4: "Google AdSense friendly",
    feature5: "Unlimited posts",
    feature6: "Unlimited Authors",
  },
  {
    title: "A personal | company portfolio website",
    imgUrl1: assets.Azhar,
    imgUrl2: assets.CompanyPortfolio,
    feature1: "Elegant design",
    feature2: "Interactive user interface",
    feature3: "Advance animations",
    feature4: "All must have sections",
    feature5: "Unlimited Projects listing",
    feature6: "",
  },
];
